import { CATEGORY } from './catalog_categories.js';
import { THEMATIC } from './catalog_thematics.js';

import PathImgWedding_001 from '../images_categories/wedding/img_001.jpg';
import PathImgWedding_002_001 from '../images_categories/wedding/img_002_01.jpg';
import PathImgWedding_002_002 from '../images_categories/wedding/img_002_02.jpg';
import PathImgWedding_002_003 from '../images_categories/wedding/img_002_03.jpg';

const PICTURES_WEDDING = [
    {
        src: PathImgWedding_002_001,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgWedding_002_002,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgWedding_002_003,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgWedding_001,
        category: CATEGORY.wedding,
        thematic: THEMATIC.none,
        title: CATEGORY.wedding.title,
        width: 720,
        height: 1000
    }
]

export { PICTURES_WEDDING }