import { CATEGORY } from './catalog_categories.js';
import { THEMATIC } from './catalog_thematics.js';

import PathImgBabyshower_001 from '../images_categories/babyshower/img_001.jpg';
import PathImgBabyshower_002 from '../images_categories/babyshower/img_002.jpg';
import PathImgBabyshower_003 from '../images_categories/babyshower/img_003.jpg';
import PathImgBabyshower_004_001 from '../images_categories/babyshower/img_004_01.jpg';
import PathImgBabyshower_004_002 from '../images_categories/babyshower/img_004_02.jpg';
import PathImgBabyshower_005_001 from '../images_categories/babyshower/img_005_001.jpg';
import PathImgBabyshower_005_002 from '../images_categories/babyshower/img_005_002.jpg';
import PathImgBabyshower_005_006 from '../images_categories/babyshower/img_005_006.jpg';
import PathImgBabyshower_006_001 from '../images_categories/babyshower/img_006_001.jpg';
import PathImgBabyshower_006_003 from '../images_categories/babyshower/img_006_003.jpg';
import PathImgBabyshower_006_004 from '../images_categories/babyshower/img_006_004.jpg';
import PathImgBabyshower_007_002 from '../images_categories/babyshower/img_007_002.jpg';
import PathImgBabyshower_007_003 from '../images_categories/babyshower/img_007_003.jpg';
import PathImgBabyshower_007_005 from '../images_categories/babyshower/img_007_005.jpg';
import PathImgBabyshower_008_001 from '../images_categories/babyshower/img_008_001.jpg';
import PathImgBabyshower_008_002 from '../images_categories/babyshower/img_008_002.jpg';
import PathImgBabyshower_008_008 from '../images_categories/babyshower/img_008_008.jpg';
import PathImgBabyshower_009_001 from '../images_categories/babyshower/img_009_001.jpg';
import PathImgBabyshower_009_002 from '../images_categories/babyshower/img_009_002.jpg';
import PathImgBabyshower_009_005 from '../images_categories/babyshower/img_009_005.jpg';
import PathImgBabyshower_009_006 from '../images_categories/babyshower/img_009_006.jpg';
import PathImgBabyshower_010_001 from '../images_categories/babyshower/img_010_001.jpg';


const PICTURES_BABYSHOWER = [
    {
        src: PathImgBabyshower_005_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_005_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_005_006,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_008_008,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunesBabies,
        title: THEMATIC.looneyTunesBabies.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_008_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunesBabies,
        title: THEMATIC.looneyTunesBabies.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_008_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunesBabies,
        title: THEMATIC.looneyTunesBabies.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_004_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_004_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_006_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_006_003,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_006_004,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.safari,
        title: THEMATIC.safari.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_009_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.winnieThePooh,
        title: THEMATIC.winnieThePooh.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_009_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.winnieThePooh,
        title: THEMATIC.winnieThePooh.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_009_005,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.winnieThePooh,
        title: THEMATIC.winnieThePooh.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_009_006,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.winnieThePooh,
        title: THEMATIC.winnieThePooh.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_007_005,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_007_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_007_003,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBabyshower_010_001,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.looneyTunesBabies,
        title: CATEGORY.babyshower.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBabyshower_003,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 900,
        height: 1000
    },
    {
        src: PathImgBabyshower_002,
        category: CATEGORY.babyshower,
        thematic: THEMATIC.none,
        title: CATEGORY.babyshower.title,
        width: 720,
        height: 1000
    }
]

export { PICTURES_BABYSHOWER }