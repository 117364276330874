import { CATEGORY } from './catalog_categories.js';
import { THEMATIC } from './catalog_thematics.js';

import PathImgRomantic_001_001 from '../images_categories/romantic/img_001_001.jpg'
import PathImgRomantic_001_002 from '../images_categories/romantic/img_001_002.jpg'
import PathImgRomantic_001_005 from '../images_categories/romantic/img_001_005.jpg'
import PathImgRomantic_002_001 from '../images_categories/romantic/img_002_001.jpg'
import PathImgRomantic_002_003 from '../images_categories/romantic/img_002_003.jpg'
import PathImgRomantic_002_004 from '../images_categories/romantic/img_002_004.jpg'
import PathImgRomantic_002_005 from '../images_categories/romantic/img_002_005.jpg'
import PathImgRomantic_003_001 from '../images_categories/romantic/img_003_001.jpg'
import PathImgRomantic_003_002 from '../images_categories/romantic/img_003_002.jpg'
import PathImgRomantic_003_004 from '../images_categories/romantic/img_003_004.jpg'
import PathImgRomantic_004_001 from '../images_categories/romantic/img_004_001.jpg'
import PathImgRomantic_004_002 from '../images_categories/romantic/img_004_002.jpg'
import PathImgRomantic_004_004 from '../images_categories/romantic/img_004_004.jpg'


const PICTURES_ROMANTIC = [
    {
        src: PathImgRomantic_004_001,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_004_002,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_004_004,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_002_001,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_002_003,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_002_004,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_002_005,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_003_001,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_003_002,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_003_004,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveMarriageProposal,
        title: THEMATIC.loveMarriageProposal.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_001_001,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveAnniversary,
        title: THEMATIC.loveAnniversary.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgRomantic_001_002,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveAnniversary,
        title: THEMATIC.loveAnniversary.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgRomantic_001_005,
        category: CATEGORY.romantic,
        thematic: THEMATIC.loveAnniversary,
        title: THEMATIC.loveAnniversary.title,
        width: 1200,
        height: 1000
    },
]

export { PICTURES_ROMANTIC }