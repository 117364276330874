import { createGlobalStyle } from 'styled-components';

import AvenirBook from '../fonts/Avenir-Book.woff';
import AvenirBook2 from '../fonts/Avenir-Book.woff2';

import AvenirBlack from '../fonts/Avenir-Black.woff';
import AvenirBlack2 from '../fonts/Avenir-Black.woff2';

import AvenirMedium from '../fonts/Avenir-Medium.woff';
import AvenirMedium2 from '../fonts/Avenir-Medium.woff2';

import AvenirLight from '../fonts/Avenir-Light.woff';
import AvenirLight2 from '../fonts/Avenir-Light.woff2';

import UnicornsAreAwesome from '../fonts/unicornsareawesome.woff';
import UnicornsAreAwesome2 from '../fonts/unicornsareawesome.woff2';

import NoteworthyLight from '../fonts/noteworthy-light.woff';
import NoteworthyLight2 from '../fonts/noteworthy-light.woff2';

import NoteworthyBold from '../fonts/noteworthy-bold.woff';
import NoteworthyBold2 from '../fonts/noteworthy-bold.woff2';




const FontUnicornsAreAwesome = createGlobalStyle`
    @font-face {
        font-family: 'unicorns_are_awesomeregular';
        src:
        url(${UnicornsAreAwesome2}) format('woff2'),
        url(${UnicornsAreAwesome}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
`;

const FontAvenirBook = createGlobalStyle`
    @font-face {
        font-family: 'Avenir Book';
        src: url(${AvenirBook2}) format('woff2'),
            url(${AvenirBook}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAvenirBlack = createGlobalStyle`
    @font-face {
        font-family: 'Avenir Black';
        src: url(${AvenirBlack2}) format('woff2'),
            url(${AvenirBlack}) format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAvenirMedium = createGlobalStyle`
    @font-face {
        font-family: 'Avenir Medium';
        src: url(${AvenirMedium2}) format('woff2'),
            url(${AvenirMedium}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
`;

const FontAvenirLight = createGlobalStyle`
    @font-face {
    font-family: 'Avenir Light';
    src: url(${AvenirLight2}) format('woff2'),
        url(${AvenirLight}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
`;

const FontNoteworthyLight = createGlobalStyle`
    @font-face {
        font-family: 'Noteworthy Light';
        src:
        url(${NoteworthyLight2}) format('woff2'),
        url(${NoteworthyLight}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;

const FontNoteworthyBold = createGlobalStyle`
    @font-face {
        font-family: 'Noteworthy Bold';
        src:
        url(${NoteworthyBold2}) format('woff2'),
        url(${NoteworthyBold}) format('woff');
        font-weight: bold;
        font-style: normal;
    }
`;


export { 
    FontUnicornsAreAwesome,
    FontNoteworthyLight,
    FontNoteworthyBold,
    FontAvenirBook,
    FontAvenirBlack,
    FontAvenirMedium,
    FontAvenirLight
}