
const THEMATIC = {
    mickey: {
        title: "Disney - Mickey Mouse",
        searchLabel: "disney mickey mouse",
        searchCode: "disney_mickey_mouse"
    },
    minnie: {
        title: "Disney - Minnie",
        searchLabel: "disney minnie, minie mouse",
        searchCode: "disney_minnie_mouse"
    },
    frozenPrincess: {
        title: "Disney - La princesa Frozen",
        searchLabel: "disney la princesa frozen, la frozen",
        searchCode: "disney_princess_frozen"
    },
    sofiaPrincess: {
        title: "Disney - La princesa Sofía",
        searchLabel: "disney la princesa sofía, disney la princesa sofia",
        searchCode: "disney_princess_sofia"
    },
    jazminPrincess: {
        title: "Disney - La princesa Jazmín",
        searchLabel: "disney la princesa jazmín, disney la princesa jazmin, disney la princesa jasmin, disney la princesa yasmin",
        searchCode: "disney_princess_jazmin"
    },
    rapunzelPrincess: {
        title: "Disney - La princesa Rapunzel",
        searchLabel: "disney la princesa rapunzel",
        searchCode: "disney_princess_rapunzel"
    },
    tianaPrincess: {
        title: "Disney - La princesa Tiana",
        searchLabel: "disney la princesa tiana",
        searchCode: "disney_princess_tiana"
    },
    disneyPrincess: {
        title: "Disney - Princesas",
        searchLabel: "disney princesas, princesas disney, disney princess",
        searchCode: "disney_princess"
    },
    mermaid: {
        title: "Disney - La Sirenita",
        searchLabel: "disney la sirenita",
        searchCode: "disney_mermaid"
    },
    theGiftOfFamily: {
        title: "Disney - Encanto",
        searchLabel: "disney encanto, el regalo de la familia",
        searchCode: "disney_gift_of_family"
    },
    winnieThePooh: {
        title: "Disney - Winnie The Pooh",
        searchLabel: "disney winnie the pooh",
        searchCode: "disney_winnie_the_pooh"
    },
    looneyTunesBabies: {
        title: "Looney Tunes Bebés",
        searchLabel: "los looney tunes bebés, los lonney tunes bebés, los luney tunes bebés",
        searchCode: "looney_tunes_babies"
    },
    spiderman: {
        title: "Spiderman",
        searchLabel: "spiderman, el hombre araña",
        searchCode: "spiderman"
    },
    barbie: {
        title: "Barbie",
        searchLabel: "la barbie",
        searchCode: "barbie"
    },
    skibidiToilet: {
        title: "Skibidi Toilet",
        searchLabel: "skibidi toilet",
        searchCode: "skibidi_toilet"
    },
    mashaAndBear: {
        title: "Masha y el oso",
        searchLabel: "la masha y el oso",
        searchCode: "masha_and_bear"
    },
    bee: {
        title: "La abejita",
        searchLabel: "la abeja, la abejita",
        searchCode: "bee"
    },
    capybara: {
        title: "Capibara",
        searchLabel: "capibara, capybara, capivara, capyvara",
        searchCode: "capybara"
    },
    farm: {
        title: "La granja",
        searchLabel: "la granja",
        searchCode: "farm"
    },
    zoo: {
        title: "El Zoológico",
        searchLabel: "el zoológico, el zoologico",
        searchCode: "the_zoo"
    },
    heny: {
        title: "La Gallina pintadita",
        searchLabel: "la gallina pintadita",
        searchCode: "the_heny"
    },
    kuromi: {
        title: "Kuromi",
        searchLabel: "kuromi",
        searchCode: "kuromi"
    },
    safari: {
        title: "Safari",
        searchLabel: "safari",
        searchCode: "safari"
    },
    pawPatroll: {
        title: "Paw Patroll",
        searchLabel: "paw patroll, pow patroll",
        searchCode: "paw_patroll"
    },
    marioBros: {
        title: "Mario Bros",
        searchLabel: "super mario bros, súper mario bros",
        searchCode: "mario_bros"
    },
    pokemon: {
        title: "Pokémon",
        searchLabel: "pokemon, pokémon",
        searchCode: "pokemon"
    },
    plimplim: {
        title: "Plim Plim",
        searchLabel: "plim plim, plimplim",
        searchCode: "pokemon"
    },
    marvelDeadPool: {
        title: "Deadpool",
        searchLabel: "marvel deadpool, marvel dead pool",
        searchCode: "deadpool"
    },
    neon: {
        title: "Neon",
        searchLabel: "neon, neón",
        searchCode: "neon"
    },
    soccer: {
        title: "Fútbol",
        searchLabel: "soccer, futbol, cristiano ronaldo, partido, alianza lima",
        searchCode: "soccer"
    },
    // TODO. schoolAnniversary must be a celebration subcategory.
    // TODO. Thematic list should have: educationCenterToddlers, educationCenterKids, edducationCenterTeen
    schoolAnniversary: {
        title: "Aniversario escolar",
        searchLabel: "aniversario escolar, centro educativo",
        searchCode: "school_anniversary"
    },
    schoolPromo: {
        title: "Promoción escolar",
        searchLabel: "promoción escolar, promocion escolar, centro educativo",
        searchCode: "school_promo"
    },
    motherDay: {
        title: "Día de la madre",
        searchLabel: "día de la madre, dia de la madre, día de la mamá",
        searchCode: "mother_day"
    },
    childDay: {
        title: "Día del niño",
        searchLabel: "día del niño, dia del niño",
        searchCode: "child_day"
    },
    graduation: {
        title: "Graduación",
        searchLabel: "graduación graduacion",
        searchCode: "graduation"
    },
    musicCriollaDay: {
        title: "Día de la canción criolla",
        searchLabel: "día de la canción criolla, dia de la cancion criolla, día de la música criolla, dia de la musica criolla",
        searchCode: "music_criolla_day"
    },
    loveMarriageProposal: {
        title: "Pedida de mano",
        searchLabel: "pedida de mano, proposición de casamiento, compromiso",
        searchCode: "marriage_proposal"
    },
    loveAnniversary: {
        title: "Aniversario de amor",
        searchLabel: "aniversario de enamorados, aniversario de casados",
        searchCode: "love_anniversary"
    },
    christmas: {
        title: "Navidad",
        searchLabel: "feliz navidad, merry christmas",
        searchCode: "christmas"
    },
    none: {
        title: "----",
        searchLabel: "----",
        searchCode: "none"
    }
}

export { THEMATIC }