import { CATEGORY } from './catalog_categories.js';
import { THEMATIC } from './catalog_thematics.js';

import PathImgBirth01_001 from '../images_categories/birth01/img_001.jpg';
import PathImgBirth01_002_001 from '../images_categories/birth01/img_002_001.jpg';
import PathImgBirth01_003_001 from '../images_categories/birth01/img_003_001.jpg';
import PathImgBirth01_003_003 from '../images_categories/birth01/img_003_003.jpg';
import PathImgBirth01_004_001 from '../images_categories/birth01/img_004_001.jpg';
import PathImgBirth01_004_003 from '../images_categories/birth01/img_004_003.jpg';
import PathImgBirth01_004_004 from '../images_categories/birth01/img_004_004.jpg';
import PathImgBirth01_005_001 from '../images_categories/birth01/img_005_001.jpg';
import PathImgBirth01_005_004 from '../images_categories/birth01/img_005_004.jpg';
import PathImgBirth01_006_001 from '../images_categories/birth01/img_006_001.jpg';
import PathImgBirth01_007_001 from '../images_categories/birth01/img_007_001.jpg';
import PathImgBirth01_007_002 from '../images_categories/birth01/img_007_002.jpg';
import PathImgBirth01_007_009 from '../images_categories/birth01/img_007_009.jpg';
import PathImgBirth01_008_001 from '../images_categories/birth01/img_008_001.jpg';
import PathImgBirth01_009_001 from '../images_categories/birth01/img_009_001.jpg';
import PathImgBirth01_009_003 from '../images_categories/birth01/img_009_003.jpg';
import PathImgBirth01_009_004 from '../images_categories/birth01/img_009_004.jpg';
import PathImgBirth01_009_007 from '../images_categories/birth01/img_009_007.jpg';
import PathImgBirth01_010_005 from '../images_categories/birth01/img_010_005.jpg';
import PathImgBirth01_010_006 from '../images_categories/birth01/img_010_006.jpg';
import PathImgBirth01_011_001 from '../images_categories/birth01/img_011_001.jpg';
import PathImgBirth01_011_002 from '../images_categories/birth01/img_011_002.jpg';
import PathImgBirth01_011_007 from '../images_categories/birth01/img_011_007.jpg';
import PathImgBirth01_011_009 from '../images_categories/birth01/img_011_009.jpg';
import PathImgBirth01_012_001 from '../images_categories/birth01/img_012_001.jpg';
import PathImgBirth01_012_002 from '../images_categories/birth01/img_012_002.jpg';
import PathImgBirth01_012_003 from '../images_categories/birth01/img_012_003.jpg';
import PathImgBirth01_012_005 from '../images_categories/birth01/img_012_005.jpg';
import PathImgBirth01_013_001 from '../images_categories/birth01/img_013_001.jpg';
import PathImgBirth01_013_002 from '../images_categories/birth01/img_013_002.jpg';
import PathImgBirth01_013_005 from '../images_categories/birth01/img_013_005.jpg';
import PathImgBirth01_013_006 from '../images_categories/birth01/img_013_006.jpg';
import PathImgBirth01_014_001 from '../images_categories/birth01/img_014_001.jpg';
import PathImgBirth01_014_003 from '../images_categories/birth01/img_014_003.jpg';
import PathImgBirth01_014_004 from '../images_categories/birth01/img_014_004.jpg';
import PathImgBirth01_015_001 from '../images_categories/birth01/img_015_001.jpg';
import PathImgBirth01_015_002 from '../images_categories/birth01/img_015_002.jpg';
import PathImgBirth01_015_003 from '../images_categories/birth01/img_015_003.jpg';
import PathImgBirth01_015_008 from '../images_categories/birth01/img_015_008.jpg';
import PathImgBirth01_016 from '../images_categories/birth01/img_016.jpg';
import PathImgBirth01_017_001 from '../images_categories/birth01/img_017_001.jpg';
import PathImgBirth01_017_002 from '../images_categories/birth01/img_017_002.jpg';
import PathImgBirth01_025 from '../images_categories/birth01/img_025.jpg';
import PathImgBirth01_028 from '../images_categories/birth01/img_028.jpg';
import PathImgBirth01_032 from '../images_categories/birth01/img_032.jpg';


import PathImgBirth0214_001_001 from '../images_categories/birth0214/img_001_001.jpg';
import PathImgBirth0214_001_002 from '../images_categories/birth0214/img_001_002.jpg';
import PathImgBirth0214_001_003 from '../images_categories/birth0214/img_001_003.jpg';
import PathImgBirth0214_003_001 from '../images_categories/birth0214/img_003_001.jpg';
import PathImgBirth0214_003_007 from '../images_categories/birth0214/img_003_007.jpg';
import PathImgBirth0214_003_008 from '../images_categories/birth0214/img_003_008.jpg';
import PathImgBirth0214_003_010 from '../images_categories/birth0214/img_003_010.jpg';
import PathImgBirth0214_004_001 from '../images_categories/birth0214/img_004_001.jpg';
import PathImgBirth0214_004_003 from '../images_categories/birth0214/img_004_003.jpg';
import PathImgBirth0214_004_004 from '../images_categories/birth0214/img_004_004.jpg';
import PathImgBirth0214_005 from '../images_categories/birth0214/img_005.jpg';
import PathImgBirth0214_006_001 from '../images_categories/birth0214/img_006_001.jpg';
import PathImgBirth0214_006_002 from '../images_categories/birth0214/img_006_002.jpg';
import PathImgBirth0214_007 from '../images_categories/birth0214/img_007.jpg';
import PathImgBirth0214_008_001 from '../images_categories/birth0214/img_008_001.jpg';
import PathImgBirth0214_008_002 from '../images_categories/birth0214/img_008_002.jpg';
import PathImgBirth0214_009 from '../images_categories/birth0214/img_009.jpg';
import PathImgBirth0214_010 from '../images_categories/birth0214/img_010.jpg';
import PathImgBirth0214_011 from '../images_categories/birth0214/img_011.jpg';
import PathImgBirth0214_012 from '../images_categories/birth0214/img_012.jpg';
import PathImgBirth0214_013 from '../images_categories/birth0214/img_013.jpg';
import PathImgBirth0214_014_001 from '../images_categories/birth0214/img_014_001.jpg';
import PathImgBirth0214_014_003 from '../images_categories/birth0214/img_014_003.jpg';
import PathImgBirth0214_015_001 from '../images_categories/birth0214/img_015_001.jpg';
import PathImgBirth0214_015_004 from '../images_categories/birth0214/img_015_004.jpg';
import PathImgBirth0214_016_001 from '../images_categories/birth0214/img_016_01.jpg';
import PathImgBirth0214_016_003 from '../images_categories/birth0214/img_016_03.jpg';
import PathImgBirth0214_016_005 from '../images_categories/birth0214/img_016_05.jpg';
import PathImgBirth0214_017_001 from '../images_categories/birth0214/img_017_001.jpg';
import PathImgBirth0214_017_004 from '../images_categories/birth0214/img_017_004.jpg';
import PathImgBirth0214_018_001 from '../images_categories/birth0214/img_018_001.jpg';
import PathImgBirth0214_018_004 from '../images_categories/birth0214/img_018_004.jpg';
import PathImgBirth0214_019 from '../images_categories/birth0214/img_019.jpg';
import PathImgBirth0214_021 from '../images_categories/birth0214/img_021.jpg';
import PathImgBirth0214_022 from '../images_categories/birth0214/img_022.jpg';
import PathImgBirth0214_023 from '../images_categories/birth0214/img_023.jpg';
import PathImgBirth0214_024 from '../images_categories/birth0214/img_024.jpg';
import PathImgBirth0214_025_001 from '../images_categories/birth0214/img_025_01.jpg';
import PathImgBirth0214_025_003 from '../images_categories/birth0214/img_025_03.jpg';
import PathImgBirth0214_026 from '../images_categories/birth0214/img_026.jpg';
import PathImgBirth0214_028_001 from '../images_categories/birth0214/img_028_01.jpg';
import PathImgBirth0214_029_001 from '../images_categories/birth0214/img_029_001.jpg';
import PathImgBirth0214_029_003 from '../images_categories/birth0214/img_029_003.jpg';
import PathImgBirth0214_030 from '../images_categories/birth0214/img_030.jpg';
import PathImgBirth0214_031_001 from '../images_categories/birth0214/img_031_001.jpg';
import PathImgBirth0214_031_002 from '../images_categories/birth0214/img_031_002.jpg';
import PathImgBirth0214_032_001 from '../images_categories/birth0214/img_032_01.jpg';
import PathImgBirth0214_032_002 from '../images_categories/birth0214/img_032_02.jpg';
import PathImgBirth0214_032_003 from '../images_categories/birth0214/img_032_03.jpg';
import PathImgBirth0214_032_004 from '../images_categories/birth0214/img_032_04.jpg';
import PathImgBirth0214_033 from '../images_categories/birth0214/img_033.jpg';
import PathImgBirth0214_034_003 from '../images_categories/birth0214/img_034_003.jpg';
import PathImgBirth0214_034_001 from '../images_categories/birth0214/img_034_001.jpg';
import PathImgBirth0214_034_002 from '../images_categories/birth0214/img_034_002.jpg';
import PathImgBirth0214_035_002 from '../images_categories/birth0214/img_035_002.jpg';
import PathImgBirth0214_036_001 from '../images_categories/birth0214/img_036_001.jpg';
import PathImgBirth0214_036_002 from '../images_categories/birth0214/img_036_002.jpg';
import PathImgBirth0214_036_003 from '../images_categories/birth0214/img_036_003.jpg';
import PathImgBirth0214_036_004 from '../images_categories/birth0214/img_036_004.jpg';
import PathImgBirth0214_037_001 from '../images_categories/birth0214/img_037_001.jpg';
import PathImgBirth0214_037_002 from '../images_categories/birth0214/img_037_002.jpg';
import PathImgBirth0214_037_004 from '../images_categories/birth0214/img_037_004.jpg';
import PathImgBirth0214_037_006 from '../images_categories/birth0214/img_037_006.jpg';
import PathImgBirth0214_038_001 from '../images_categories/birth0214/img_038_001.jpg';
import PathImgBirth0214_038_002 from '../images_categories/birth0214/img_038_002.jpg';
import PathImgBirth0214_038_003 from '../images_categories/birth0214/img_038_003.jpg';
import PathImgBirth0214_039_001 from '../images_categories/birth0214/img_039_001.jpg';
import PathImgBirth0214_039_002 from '../images_categories/birth0214/img_039_002.jpg';
import PathImgBirth0214_041_001 from '../images_categories/birth0214/img_041_001.jpg';
import PathImgBirth0214_041_002 from '../images_categories/birth0214/img_041_002.jpg';
import PathImgBirth0214_042_001 from '../images_categories/birth0214/img_042_001.jpg';
import PathImgBirth0214_042_002 from '../images_categories/birth0214/img_042_002.jpg';
import PathImgBirth0214_043_001 from '../images_categories/birth0214/img_043_001.jpg';
import PathImgBirth0214_043_002 from '../images_categories/birth0214/img_043_002.jpg';
import PathImgBirth0214_043_003 from '../images_categories/birth0214/img_043_003.jpg';


import PathImgBirth15_001 from '../images_categories/birth15/img_001.jpg';
import PathImgBirth15_002 from '../images_categories/birth15/img_002.jpg';
import PathImgBirth15_003_001 from '../images_categories/birth15/img_003_01.jpg';
import PathImgBirth15_003_002 from '../images_categories/birth15/img_003_02.jpg';
import PathImgBirth15_004_001 from '../images_categories/birth15/img_004_01.jpg';
import PathImgBirth15_005_001 from '../images_categories/birth15/img_005_001.jpg';
import PathImgBirth15_006_001 from '../images_categories/birth15/img_006_001.jpg';
import PathImgBirth15_006_007 from '../images_categories/birth15/img_006_007.jpg';
import PathImgBirth15_008_001 from '../images_categories/birth15/img_008_001.jpg';
import PathImgBirth15_008_002 from '../images_categories/birth15/img_008_002.jpg';
import PathImgBirth15_008_003 from '../images_categories/birth15/img_008_003.jpg';
import PathImgBirth15_009_003 from '../images_categories/birth15/img_009_003.jpg';
import PathImgBirth15_009_006 from '../images_categories/birth15/img_009_006.jpg';
import PathImgBirth15_009_008 from '../images_categories/birth15/img_009_008.jpg';
import PathImgBirth15_009_009 from '../images_categories/birth15/img_009_009.jpg';


import PathImgBirth50G_001_001 from '../images_categories/birth50G/img_001_001.jpg';
import PathImgBirth50G_001_002 from '../images_categories/birth50G/img_001_002.jpg';
import PathImgBirth50G_001 from '../images_categories/birth50G/img_001.jpg';
import PathImgBirth50G_002_001 from '../images_categories/birth50G/img_002_001.jpg';
import PathImgBirth50G_002_002 from '../images_categories/birth50G/img_002_002.jpg';
import PathImgBirth50G_002_004 from '../images_categories/birth50G/img_002_004.jpg';
import PathImgBirth50G_003_001 from '../images_categories/birth50G/img_003_001.jpg';
import PathImgBirth50G_003_002 from '../images_categories/birth50G/img_003_002.jpg';
import PathImgBirth50G_004 from '../images_categories/birth50G/img_004.jpg';
import PathImgBirth50G_005_001 from '../images_categories/birth50G/img_005_001.jpg';
import PathImgBirth50G_005_002 from '../images_categories/birth50G/img_005_002.jpg';
import PathImgBirth50G_006 from '../images_categories/birth50G/img_006.jpg';
import PathImgBirth50G_007_001 from '../images_categories/birth50G/img_007_001.jpg';
import PathImgBirth50G_007_002 from '../images_categories/birth50G/img_007_002.jpg';
import PathImgBirth50G_009_004 from '../images_categories/birth50G/img_009_004.jpg';
import PathImgBirth50G_009_002 from '../images_categories/birth50G/img_009_002.jpg';
import PathImgBirth50G_010_001 from '../images_categories/birth50G/img_010_001.jpg';
import PathImgBirth50G_010_002 from '../images_categories/birth50G/img_010_002.jpg';
import PathImgBirth50G_011_001 from '../images_categories/birth50G/img_011_001.jpg';
import PathImgBirth50G_011_003 from '../images_categories/birth50G/img_011_003.jpg';



import PathImgBirth_001_001 from '../images_categories/birth/img_001_001.jpg';
import PathImgBirth_001_002 from '../images_categories/birth/img_001_002.jpg';
import PathImgBirth_002_001 from '../images_categories/birth/img_002_001.jpg';
import PathImgBirth_002_002 from '../images_categories/birth/img_002_002.jpg';
import PathImgBirth_002_003 from '../images_categories/birth/img_002_003.jpg';
import PathImgBirth_003_001 from '../images_categories/birth/img_003_001.jpg';
import PathImgBirth_003_003 from '../images_categories/birth/img_003_003.jpg';
import PathImgBirth_008 from '../images_categories/birth/img_008.jpg';
import PathImgBirth_014 from '../images_categories/birth/img_014.jpg';
import PathImgBirth_015 from '../images_categories/birth/img_015.jpg';
import PathImgBirth_017 from '../images_categories/birth/img_017.jpg';
import PathImgBirth_018 from '../images_categories/birth/img_018.jpg';
import PathImgBirth_020 from '../images_categories/birth/img_020.jpg';
import PathImgBirth_029 from '../images_categories/birth/img_029.jpg';
import PathImgBirth_031_001 from '../images_categories/birth/img_031.jpg';
import PathImgBirth_036_001 from '../images_categories/birth/img_036_001.jpg';
import PathImgBirth_036_003 from '../images_categories/birth/img_036_003.jpg';
import PathImgBirth_036_004 from '../images_categories/birth/img_036_004.jpg';


const PICTURES_BIRTH = [
    {
        src: PathImgBirth01_009_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_009_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_009_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_009_007,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_009_008,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifteen_years.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth15_009_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifteen_years.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_009_006,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifteen_years.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_009_009,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifteen_years.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth50G_001_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifty_greater_years.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_001_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifty_greater_years.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_005_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifty_greater_years.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_005_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        title: CATEGORY.birth.subcategories.fifty_greater_years.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_007_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.winnieThePooh,
        title: THEMATIC.winnieThePooh.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_007_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.winnieThePooh,
        title: THEMATIC.winnieThePooh.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_007_009,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.winnieThePooh,
        title: THEMATIC.winnieThePooh.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_013_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_013_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_013_005,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_013_006,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_037_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pawPatroll,
        title: THEMATIC.pawPatroll.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_037_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pawPatroll,
        title: THEMATIC.pawPatroll.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_037_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pawPatroll,
        title: THEMATIC.pawPatroll.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_037_006,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pawPatroll,
        title: THEMATIC.pawPatroll.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_036_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth_036_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth_036_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_012_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.minnie,
        title: THEMATIC.minnie.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_012_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.minnie,
        title: THEMATIC.minnie.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_012_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.minnie,
        title: THEMATIC.minnie.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_012_005,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.minnie,
        title: THEMATIC.minnie.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_008_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.tianaPrincess,
        title: THEMATIC.tianaPrincess.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth15_008_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.tianaPrincess,
        title: THEMATIC.tianaPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_008_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.tianaPrincess,
        title: THEMATIC.tianaPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_014_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mashaAndBear,
        title: THEMATIC.mashaAndBear.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_014_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mashaAndBear,
        title: THEMATIC.mashaAndBear.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_014_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mashaAndBear,
        title: THEMATIC.mashaAndBear.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_018_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.frozenPrincess,
        title: THEMATIC.frozenPrincess.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_018_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.frozenPrincess,
        title: THEMATIC.frozenPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_001_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_001_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_011_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.looneyTunesBabies,
        title: THEMATIC.looneyTunesBabies.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_011_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.looneyTunesBabies,
        title: THEMATIC.looneyTunesBabies.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_011_007,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.looneyTunesBabies,
        title: THEMATIC.looneyTunesBabies.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_011_009,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.looneyTunesBabies,
        title: THEMATIC.looneyTunesBabies.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_015_008,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_015_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_015_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_015_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_004_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_017_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.bee,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_017_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.bee,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_036_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Celebramos el cumpleaños de la pequeña Danae!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_036_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Celebramos el cumpleaños de la pequeña Danae!",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_036_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Celebramos el cumpleaños de la pequeña Danae!",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_036_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Celebramos el cumpleaños de la pequeña Danae!",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_002_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth50G_002_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_002_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_039_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mermaid,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_039_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mermaid,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_041_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.marvelDeadPool,
        title: THEMATIC.marvelDeadPool.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_041_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.marvelDeadPool,
        title: THEMATIC.marvelDeadPool.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_034_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.neon,
        title: THEMATIC.neon.title,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_034_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.neon,
        title: THEMATIC.neon.title,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_034_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.neon,
        title: THEMATIC.neon.title,
        description: "Un día inolvidable",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth15_006_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 900,
        height: 1000
    },
    {
        src: PathImgBirth15_006_007,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_015_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.frozenPrincess,
        title: THEMATIC.frozenPrincess.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_015_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.frozenPrincess,
        title: THEMATIC.frozenPrincess.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_017_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.farm,
        title: THEMATIC.farm.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_017_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.farm,
        title: THEMATIC.farm.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_009_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_009_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_010_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_010_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_029_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mermaid,
        title: THEMATIC.mermaid.title,
        description: "Celebrando los seis años de la pequeña Gloria.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_029_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mermaid,
        title: THEMATIC.mermaid.title,
        description: "Celebrando los seis años de la pequeña Gloria.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_003_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Un día maravilloso",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_003_007,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Un día maravilloso",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_003_008,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Un día maravilloso",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_003_010,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Un día maravilloso",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_006_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.skibidiToilet,
        title: THEMATIC.skibidiToilet.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_006_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.skibidiToilet,
        title: THEMATIC.skibidiToilet.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_014_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.kuromi,
        title: THEMATIC.kuromi.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_014_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.kuromi,
        title: THEMATIC.kuromi.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_042_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mashaAndBear,
        title: THEMATIC.mashaAndBear.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_042_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mashaAndBear,
        title: THEMATIC.mashaAndBear.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_005_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.jazminPrincess,
        title: THEMATIC.jazminPrincess.title,
        description: "En los quince años de la señorita Yasmín.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_043_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.capybara,
        title: THEMATIC.capybara.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_043_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.capybara,
        title: THEMATIC.capybara.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_043_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.capybara,
        title: THEMATIC.capybara.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_002_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 900,
        height: 1000
    },
    {
        src: PathImgBirth_002_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_002_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_005_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        description: "El primer añito del pequeño Patrick.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_005_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        description: "El primer añito del pequeño Patrick.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_031_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.kuromi,
        title: THEMATIC.kuromi.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_031_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.kuromi,
        title: THEMATIC.kuromi.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_011_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_011_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        description: "Un día inolvidable",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_002_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.farm,
        title: THEMATIC.farm.title,
        description: "El primer añito de la pequeña Rafaella.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_028_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.minnie,
        title: THEMATIC.minnie.title,
        description: "El primer añito de la pequeña Valentina.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_032_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Los ocho años de la pequeña Danella.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_032_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Los ocho años de la pequeña Danella.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_032_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Los ocho años de la pequeña Danella.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_032_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Los ocho años de la pequeña Danella.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_004_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.safari,
        title: THEMATIC.safari.title,
        description: "Celebrando el primer añito de Elian con la temática Safari.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_004_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.safari,
        title: THEMATIC.safari.title,
        description: "Celebrando el primer añito de Elian con la temática Safari.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_004_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.safari,
        title: THEMATIC.safari.title,
        description: "Celebrando el primer añito de Elian con la temática Safari.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_001_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.neon,
        title: THEMATIC.neon.title,
        description: "Celebrando los catorce años de Diego con la temática neón.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_001_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.neon,
        title: THEMATIC.neon.title,
        description: "Celebrando los catorce años de Diego con la temática neón.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_001_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.neon,
        title: THEMATIC.neon.title,
        description: "Celebrando los catorce años de Diego con la temática neón.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_004_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.spiderman,
        title: THEMATIC.spiderman.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_004_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.spiderman,
        title: THEMATIC.spiderman.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_004_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.spiderman,
        title: THEMATIC.spiderman.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_007_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_007_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_009,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.frozenPrincess,
        title: THEMATIC.frozenPrincess.title,
        description: "Un día inolvidable en el tercer cumpleaños de la pequeña Ariana.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_019,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.theGiftOfFamily,
        title: THEMATIC.theGiftOfFamily.title,
        description: "Celebrando el cumpleaños de la pequeña Mariel.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_025_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.rapunzelPrincess,
        title: THEMATIC.rapunzelPrincess.title,
        description: "Festejando el octavo cumpleaños de la pequeña Irisa.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_025_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.rapunzelPrincess,
        title: THEMATIC.rapunzelPrincess.title,
        description: "Festejando el octavo cumpleaños de la pequeña Irisa.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_038_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.soccer,
        title: THEMATIC.soccer.title,
        description: "Festejando el quinto cumpleaños de Emmanuel.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_038_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.soccer,
        title: THEMATIC.soccer.title,
        description: "Festejando el quinto cumpleaños de Emmanuel.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_038_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.soccer,
        title: THEMATIC.soccer.title,
        description: "Festejando el quinto cumpleaños de Emmanuel.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_003_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.soccer,
        title: THEMATIC.soccer.title,
        description: "Celebramos el cumpleaños de Jhonny.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_003_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.soccer,
        title: THEMATIC.soccer.title,
        description: "Celebramos el cumpleaños de Jhonny.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_010_005,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.minnie,
        title: THEMATIC.minnie.title,
        description: "Festejando el quinto cumpleaños de la pequeña Anny Lu.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_010_006,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.minnie,
        title: THEMATIC.minnie.title,
        description: "Festejando el quinto cumpleaños de la pequeña Anny Lu.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_008_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.zoo,
        title: THEMATIC.zoo.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth50G_003_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_003_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_008_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.heny,
        title: THEMATIC.heny.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_008_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.heny,
        title: THEMATIC.heny.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_031_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_003_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        description: "Festejando los tres meses del bebé Adriel.",
        width: 900,
        height: 1000
    },
    {
        src: PathImgBirth01_003_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        description: "Festejando los tres meses del bebé Adriel.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.mermaid,
        title: THEMATIC.mermaid.title,
        description: "Celebramos el primer añito de la pequeña Ally.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_022,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mashaAndBear,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_016_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pawPatroll,
        title: THEMATIC.pawPatroll.title,
        description: "Celebramos el segundo añito del pequeño Constantino.",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_016_003,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pawPatroll,
        title: THEMATIC.pawPatroll.title,
        description: "Celebramos el segundo añito del pequeño Constantino.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_016_005,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pawPatroll,
        title: THEMATIC.pawPatroll.title,
        description: "Celebramos el segundo añito del pequeño Constantino.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_005,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.sofiaPrincess,
        title: THEMATIC.sofiaPrincess.title,
        description: "Un día inolvidable.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth_008,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_035_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_003_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_003_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_021,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.farm,
        title: THEMATIC.farm.title,
        description: "Un día inolvidable.",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_033,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.marioBros,
        title: THEMATIC.marioBros.title,
        description: "Celebramos el cumpleaños del pequeño Jackson con la temática de Mario Bros!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_011,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mermaid,
        title: THEMATIC.mermaid.title,
        description: "Celebramos los siete años de la pequeña Gia con la temática de La Sirenita!",
        width: 900,
        height: 1000
    },
    {
        src: PathImgBirth_015,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_025,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.safari,
        title: THEMATIC.safari.title,
        description: "Felicidades Rafael por el día de tu primer añito!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_028,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.farm,
        title: THEMATIC.farm.title,
        description: "Felicidades Juan por el día de tu primer añito!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_032,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth_029,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_012,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.pokemon,
        title: THEMATIC.pokemon.title,
        description: "Festejando el octavo cumpleaños de Mateo con la temática de Pokemon!",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_007,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.disneyPrincess,
        title: THEMATIC.disneyPrincess.title,
        description: "Festejando el septimo cumpleaños de Tahira con la temática de princesas Disney!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth01_006_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.plimplim,
        title: THEMATIC.plimplim.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth50G_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_010,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.mickey,
        title: THEMATIC.mickey.title,
        description: "Festejamos los dos añitos de Isaac con la temática de Mickey mouse!",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth_020,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth15_001,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_023,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.barbie,
        title: THEMATIC.barbie.title,
        description: "Festejamos el cumpleaños de Brianna con la temática de Barbie!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth0214_026,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.none,
        width: 900,
        height: 1000
    },
    {
        src: PathImgBirth0214_024,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_006,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth_014,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_013,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.spiderman,
        title: THEMATIC.spiderman.title,
        description: "Festejamos los cinco añitos de Mateo con la temática de Spiderman!",
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth01_016,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.one_year,
        thematic: THEMATIC.farm,
        title: THEMATIC.farm.title,
        description: "Festejamos el primer añito de Isacc con la temática de la Granja!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth_017,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 900,
        height: 1000
    },
    {
        src: PathImgBirth_018,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.others,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth50G_004,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifty_greater_years,
        thematic: THEMATIC.none,
        width: 720,
        height: 1000
    },
    {
        src: PathImgBirth0214_030,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.two_fourteen_years,
        thematic: THEMATIC.sofiaPrincess,
        title: THEMATIC.sofiaPrincess.title,
        description: "Celebrando el cumpleaños de sophia con la temática de la princesa Sofia!",
        width: 1200,
        height: 1000
    },
    {
        src: PathImgBirth15_002,
        category: CATEGORY.birth,
        subcategory: CATEGORY.birth.subcategories.fifteen_years,
        thematic: THEMATIC.none,
        width: 1200,
        height: 1000
    }
]

export { PICTURES_BIRTH }