import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import M from 'materialize-css';
import { Container, Col, Modal, RadioGroup, TextInput, Collection, CollectionItem } from 'react-materialize';
import styled from 'styled-components';
import { SIZE, LABEL, COLOR, FONT_FAMILY, URL, SCREEN_MEDIA } from '../../utils/constants.js';
import { CATEGORY } from '../../utils/catalog_categories.js';
import {
    getSearchCodeFromSearchLabel,
    getCatalogSuggestionList
} from '../../utils/managersearch.js';
import {
    BIRTH_SUBCATEGORIES_MODAL
} from '../../utils/managerthematic.js';
import Row from '../organism/row.jsx';
import Path from '../molecules/path.jsx';
import Button from '../atoms/button.jsx';
import TextIcon from '../atoms/texticon.jsx';
import Divider from '../atoms/divider';
import FixedButtons from '../molecules/fixedbuttons.jsx';
import Photos from '../organism/photos.jsx';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { Label } from '../atoms/label.jsx';



import PathWapp from '../../icons/ic_wapp.svg';
import PathFb from '../../icons/ic_fb_colored.svg';
import PathTikTok from '../../icons/ic_tiktok.svg';
import PathInsta from '../../icons/ic_instagram_colored.svg';

import PathIcSarch from '../../icons/ic_search.svg'


import PathPhotoBirth01 from '../../images_categories/birth/img_008.jpg';
import PathPhotoBirth02 from '../../images_categories/birth/img_036_004.jpg';
import PathPhotoBirth03 from '../../images_categories/birth0214/img_034_001.jpg';

import PathPhotoBabyshower01 from '../../images_categories/babyshower/img_001_01.jpg';
import PathPhotoBabyshower02 from '../../images_categories/babyshower/img_005_002.jpg';
import PathPhotoBabyshower03 from '../../images_categories/babyshower/img_004_02.jpg';

import PathPhotoCelebration01 from '../../images_categories/celebrations/img_001_vertical.jpg';
import PathPhotoCelebration02 from '../../images_categories/celebrations/img_017_003_thumbnail.jpg';
import PathPhotoCelebration03 from '../../images_categories/celebrations/img_005_001.jpg';

import PathPhotoRomantic01 from '../../images_categories/romantic/img_001_002_thumbnail.jpg';
import PathPhotoRomantic02 from '../../images_categories/romantic/img_002_005_thumbnail.jpg';
import PathPhotoRomantic03 from '../../images_categories/romantic/img_004_004_thumbnail.jpg';

import PathPhotoBaptism01 from '../../images_categories/baptism/img_001.jpg';
import PathPhotoBaptism02 from '../../images_categories/baptism/img_002.jpg';
import PathPhotoBaptism03 from '../../images_categories/baptism/img_003_00.jpg';

import PathPhotoWedding01 from '../../images/img_photo_wedding_01.jpg';
import PathPhotoWedding02 from '../../images_categories/wedding/img_001.jpg';
import PathPhotoWedding03 from '../../images_categories/wedding/img_002_02_vertical.jpg';


const RowClickable = styled(Row)`
    &:hover {
        cursor: pointer;
    }
`

const RadioContent = styled.div`
    [type="radio"] {
        &:checked+span {
            &::before {
                border: 2px solid ${COLOR.ferra};
            }
            &:after {
                background-color: ${COLOR.ferra};
                border: 1px solid ${COLOR.ferra};
            }
        }
    }
    label {
        color: ${COLOR.ferra};
        font-size: 36px;
        width: 100%;
        display: inline-block;
    }
`


const TextInputLayer = styled.div`
    height: 45px;
    width: 100%;
    border: 2px solid ${COLOR.ferra};
    border-radius: 4px;
    cursor: text;
`;


const SearchSuggestionBackground = styled.div`
    height: 100%; // It is not working, this is set dynamically
    width: 100%;
    position: absolute;
    background-color: ${COLOR.background};
    display: none;
    @media only screen and ${SCREEN_MEDIA.small} {
        top: 56px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        top: 64px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        top: 64px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        top: 64px;
    }
    z-index: 1;
`;

const SearcherSuggestionDiv = styled.div`
    height: 400px;
    background-color: ${COLOR.white};
    overflow: auto;
    position: absolute;
    @media only screen and ${SCREEN_MEDIA.small} {
        width:90%;
        top: 90px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        width:85%;
        top: 90px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        width:70%;
        top: 90px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        width:70%;
        top: 90px;
    }
`


const BodyThematic = () => {

    const wholeCatalogSuggestions = getCatalogSuggestionList('')

    const [searchValue, setSearchValue] = useState('');

    const [birthFilterModal, setBirthFilterModal] = useState();
    const [birthSubcategoryCode, setBirthSubcategoryCode] = useState(BIRTH_SUBCATEGORIES_MODAL.filterOptions[0].value);
    const [searchSuggestionList, setSearchSuggestionList] = useState(wholeCatalogSuggestions)

    const navigate = useNavigate();

    const searchInputTextRef = useRef()

    useEffect(() => {
        $(window).on('load', function() {
            // ready method is deprecated
            var birthModal = M.Modal.getInstance($(`#${BIRTH_SUBCATEGORIES_MODAL.id}`)); // Init modal
            setBirthFilterModal(birthModal);

            $('#search_suggestion_background').css('height', $('#root').height());
        });
    }, [])

    const handleBirthSubcategoryApply = () => {
        birthFilterModal.close()
        navigate(`/pictures/${birthSubcategoryCode}`)
    }

    const handleSearchOnkeyDown = (event) => {
        if (searchValue.length < 3) return

        if (event.key === 'Enter') {
            const searchCode = getSearchCodeFromSearchLabel(searchValue)

            if (searchCode === '') {
                alert(`No se encontraron resultados: ${searchCode}`)
            } else {
                navigate(`/pictures/${searchCode}`)
            }
        }
    }

    const handleOnSearchDismissed = () => {
        $(searchInputTextRef.current).blur()
        $('#search_suggestion_background').css('display', 'none')
    }

    const onSearchInputLayerClicked = () => {
        $('#search_suggestion_background').css('display', 'block')
        $(searchInputTextRef.current).focus()
    }

    const onSearchInputTextChanges = (event) => {
        const searchText = event.target.value
        const searchTrim = searchText.trim()
        setSearchValue(searchTrim)
        const searchSuggestionList = getCatalogSuggestionList(searchTrim)
        setSearchSuggestionList(searchSuggestionList)
    }

    return(
        <div>
  
            <Container>
                <FixedButtons
                    items={[
                        { href: URL.whatsapp, ic_path: PathWapp, ic_size: '50px' },
                        { href: URL.facebook, ic_path: PathFb, ic_size: '50px' },
                        { href: URL.tiktok, ic_path: PathTikTok, ic_size: '50px' },
                        { href: URL.instagram, ic_path: PathInsta, ic_size: '50px' }
                        ]}
                    aligment='right'/>

                <Row className='valign-wrapper' margin='40px 0'>
                    <TextInputLayer
                        onClick={ () => { onSearchInputLayerClicked() } }>
                        <Label
                        text_color={COLOR.gray}
                        text_size='20px'
                        font_family={FONT_FAMILY.avenirBook}
                        margin='7px 14px'>
                        Buscar temática
                        </Label>
                    </TextInputLayer>
                </Row>
                

                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={LABEL.thematics} />
                </Row>

                <RowClickable className='valign-wrapper' onClick={ () => birthFilterModal.open() }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}> 
                            { CATEGORY.birth.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoBirth01 }
                            photo_01_rotation='-5deg'
                            photo_02_src={ PathPhotoBirth02 }
                            photo_02_rotation='2deg'
                            photo_03_src={ PathPhotoBirth03 }
                            photo_03_rotation='10deg'
                            margin='30px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                {/* ****** MODAL ****** */}
                <Modal
                    id={ BIRTH_SUBCATEGORIES_MODAL.id }
                    header={ BIRTH_SUBCATEGORIES_MODAL.title }
                    fixedFooter={ false } //If true breaks DatePickers
                    actions={[
                    <Button 
                        onClick={() => { handleBirthSubcategoryApply(birthSubcategoryCode) }}
                        width='120px'
                        height='46px'
                        bg_color={COLOR.primary}
                        float='right'>
                        <Label
                            text_color={ COLOR.ferra }
                            text_size={SIZE.headline}
                            font_family={FONT_FAMILY.unicorns}
                            margin='auto'>
                            Aplicar
                        </Label>
                    </Button>
                    ]}>
                    <Row margin='30px 0 0 0'>
                        <RadioContent>
                        <RadioGroup
                            label="Birth-filter"
                            name="size"
                            className='valign-wrapper'
                            withGap={ true }
                            options={ BIRTH_SUBCATEGORIES_MODAL.filterOptions }
                            value={ birthSubcategoryCode }
                            onChange={(event) => setBirthSubcategoryCode(event.target.value)}
                            />
                        </RadioContent>
                    </Row>
                </Modal>

                <Divider/>


                <RowClickable className='valign-wrapper' onClick={ () => navigate(`/pictures/romantic`) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.romantic.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoRomantic01 }
                            photo_01_rotation='-12deg'
                            photo_02_src={ PathPhotoRomantic02 }
                            photo_02_rotation='-10deg'
                            photo_03_src={ PathPhotoRomantic03 }
                            photo_03_rotation='8deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Divider/>


                <RowClickable className='valign-wrapper' onClick={ () => navigate(`/pictures/babyshower`) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.babyshower.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoBabyshower01 }
                            photo_01_rotation='-10deg'
                            photo_02_src={ PathPhotoBabyshower02 }
                            photo_02_rotation='-3deg'
                            photo_03_src={ PathPhotoBabyshower03 }
                            photo_03_rotation='8deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Divider/>


                <RowClickable className='valign-wrapper' onClick={ () => navigate(`/pictures/celebrations`) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}> 
                            { CATEGORY.celebrations.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={PathPhotoCelebration01}
                            photo_01_rotation='-10deg'
                            photo_02_src={PathPhotoCelebration02}
                            photo_02_rotation='-2deg'
                            photo_03_src={PathPhotoCelebration03}
                            photo_03_rotation='4deg'
                            margin='30px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Divider/>
                

                <RowClickable className='valign-wrapper' onClick={ () => navigate(`/pictures/baptism`) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.baptism.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={ PathPhotoBaptism02 }
                            photo_01_rotation='-5deg'
                            photo_02_src={ PathPhotoBaptism03 }
                            photo_02_rotation='4deg'
                            photo_03_src={ PathPhotoBaptism01 }
                            photo_03_rotation='4deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>

                <Divider/>

                <RowClickable className='valign-wrapper' onClick={ () => navigate(`/pictures/wedding`) }>
                    <Col s={12} m={12} l={2} xl={2}>
                        <Label
                            text_size={SIZE.display_top}
                            text_color={COLOR.secondary}
                            text_align='center'
                            font_family={FONT_FAMILY.unicorns}
                            margin='40px 0 0 0'> 
                            { CATEGORY.wedding.title } 
                        </Label>
                    </Col>
                    <Col s={5} m={6} l={4} xl={4}>
                        <Photos
                            photo_01_src={PathPhotoWedding01}
                            photo_01_rotation='-6deg'
                            photo_02_src={PathPhotoWedding02}
                            photo_02_rotation='1deg'
                            photo_03_src={ PathPhotoWedding03 }
                            photo_03_rotation='8deg'
                            margin='50px 0'/>
                    </Col>
                    <Col s={7} m={4} l={3} xl={2}></Col>
                </RowClickable>
  
            </Container>

            <SearchSuggestionBackground
                id='search_suggestion_background'
                onClick={ () => { handleOnSearchDismissed() } }>
                <Container>
                <Row margin='40px 0'>
                    <Col s={8} m={8} l={10} xl={11}>
                        <TextInput
                        ref={searchInputTextRef}
                        placeholder='Buscar temática'
                        onChange={ (event) => { onSearchInputTextChanges(event) } }
                        onKeyDown={ (event) => { handleSearchOnkeyDown(event) } }/>
                    </Col>
                    <Col s={4} m={4} l={2} xl={1}>
                    <Button
                        onClick={ () => { handleOnSearchDismissed() } }
                        height='40px'>
                        <Label
                        font_family={FONT_FAMILY.avenirLight}
                        text_color={COLOR.ferra}>
                            { LABEL.cancel }
                        </Label>
                    </Button>
                    
                    </Col>
                </Row>
                <Row>
                    <SearcherSuggestionDiv>
                    <Collection>
                    {
                        searchSuggestionList.map((searchSuggestion, index) =>
                            <CollectionItem
                                key={index}
                                href={`/pictures/${searchSuggestion.searchCode}`}>
                                <TextIcon
                                    ic_path={PathIcSarch}
                                    ic_size='18px'
                                    ic_color={COLOR.ferra}>
                                </TextIcon>
                                <Label
                                    text_size={SIZE.subtitle}
                                    text_color={COLOR.ferra}
                                    font_family={FONT_FAMILY.avenirMedium}> 
                                    {searchSuggestion.title}
                                </Label>
                                
                            </CollectionItem>
                        )
                    }
                    </Collection>
                    </SearcherSuggestionDiv>
                </Row>
                </Container>
            </SearchSuggestionBackground>

        </div>
    );
}

export default BodyThematic;