
const COLOR = {
    primary: '#EBFCF1',
    primaryDark: '#9BE8B6',
    primaryClear: '#EBFCF1',
    gold: '#C99B02',
    secondary: '#A251A8',
    secondaryDark: '#C43B3B',
    secondaryClear: '#FF9D9C',
    background: '#FCFAF2',
    white: '#FFFFFF',
    ferra: '#6F4C5B',
    black: '#333333',
    gray: '#999999',
    grayDark: '#777777',
    grayClear: '#EDEDED',
    red: '#FF6961'
}

const SIZE = {
    display_top: '54px',
    display: '36px',
    headline_top: '32px',
    headline: '28px',
    title: '22px',
    subtitle: '18px',
    body: '16px',
    caption: '13px',
    small: '11px',
    row_margin: '80px 0',
    inp_radious: '4px' // Input border radious
}

const SCREEN_MEDIA = {
    small: `(min-width: 0px) and (max-width: 600px)`,
    medium : `(min-width: 600px) and (max-width: 992px)`,
    long: `(min-width: 992px) and (max-width: 1200px)`,
    xlong: `(min-width: 1200px)`
}

const FLOAT = {
    left: 'left',
    right: 'right'
}

const LABEL = {
    init: 'Inicio',
    about: 'Acerca de',
    us: 'Nosotros',
    sketch: 'Boceto',
    sketch_shimmer: 'Shimmer',
    sketch_shimmer_silver: 'Plateado',
    sketch_shimmer_golden: 'Dorado',
    sketch_shimmer_colors: 'Colores',
    sketch_background: 'Fondo',
    sketch_background_arch_pink: 'Arco llano rosa',
    sketch_background_arch_sky: 'Arco llano celeste',
    sketch_background_arch_green: 'Arco llano verde',
    sketch_background_square_pink: 'Cuadrado llano rosa',
    sketch_background_square_sky: 'Cuadrado llano celeste',
    sketch_background_square_green: 'Cuadrado llano verde',
    sketch_background_circle_image: 'Circular + imagen',
    sketch_background_circle_curtain: 'Circular + cortina',
    sketch_balloon: 'Globos',
    sketch_balloon_golden: 'Globo dorado',
    sketch_balloon_red: 'Globo rojo',
    sketch_balloon_white: 'Globo blanco',
    sketch_balloon_transparent: 'Globo transparente',
    sketch_balloons_group4_red: 'Grupo de rojos',
    sketch_balloons_group4_golden: 'Grupo de dorados',
    sketch_balloons_group4_white: 'Grupo de blancos',
    sketch_arch: 'Arco',
    sketch_arch_std: 'Estándar',
    sketch_arch_cornered: 'Con esquinas',
    sketch_arch_infinite: 'Infinito',
    sketch_pedestal: 'Pedestal',
    sketch_pedestal_cylinder: 'Cilindro llano',
    sketch_pedestal_square: 'Cubo llano',
    sketch_pedestal_square_trans: 'Cubo acrílico',
    sketch_pedestal_pilar: 'Pilar',
    sketch_table: 'Mesa',
    sketch_table_iron_square: 'Metal cuadrado',
    sketch_table_iron_hourglass: 'Metal hourglass',
    sketch_chairs: 'Silla',
    sketch_chair_king: 'Silla rey',
    sketch_chair_king_small: 'Silla rey small',
    sketch_podiums: 'Podio',
    sketch_podium_circle: 'Podio circular',
    sketch_number: 'Número',
    sketch_number_zero: 'Cero',
    sketch_number_one: 'Uno',
    sketch_number_three: 'Tres',
    sketch_number_five: 'Cinco',
    contact: 'Contacto',
    slogan: 'Organizamos tus momentos inolvidables',
    phone_number_01: '962 075 242',
    phone_number_02: '943 099 535',
    send: 'Enviar',
    close: 'Cerrar',
    clear_all: 'Limpiar todo',
    categories: 'Categorías',
    thematics: 'Temáticas',
    pictures: 'Fotos',
    invitations: 'Invitaciones',
    events: 'Eventos',
    thematic_kid_party: 'Fiestas infantiles',
    cancel: 'Cancelar'
}

const KEY = {
    emailJsService: 'service_dx1fner',
    emailJsTemplate: 'template_2nt1i9r',
    emailJsUserId: 'xXnVu25KX86tmQes5'
}

const URL = {
    home: "/",
    thematics: "/thematics",
    pictures: "/pictures/:searchCode",
    invitations: "/invitations",
    sketch: "/sketch",
    contact: "/contact",
    terms: "/terms",
    whatsapp: "https://api.whatsapp.com/send?phone=51962075242&app=facebook&entry_point=page_cta&fbclid=IwAR2EfDyK9ER4Zxqzcj9e6Ko-E1sSjmql6q7o-2Alp-dtYmkZQXUb_ZFetcg",
    facebook: "https://www.facebook.com/profile.php?id=100063530855182",
    tiktok: "https://www.tiktok.com/@joeeventos",
    instagram: "https://www.instagram.com/joeeventosdecoraciones/",
    email: "/contact"
}

const FONT_FAMILY = {
    noteworthyBold: "Noteworthy Bold",
    noteworthyLight: "Noteworthy Light",
    unicorns: "unicorns_are_awesomeregular",
    avenirBook: "Avenir Book",
    avenirBlack: "Avenir Black",
    avenirMedium: "Avenir Medium",
    avenirLight: "Avenir Light"
}

export {
    COLOR,
    SIZE,
    SCREEN_MEDIA,
    LABEL,
    FLOAT,
    KEY,
    URL,
    FONT_FAMILY
}