import { CATEGORY } from "./catalog_categories"

const BIRTH_SUBCATEGORIES_MODAL = {
    id: 'modalBirthFilter',
    title: 'Selecciona una categoría',
    filterOptions: [
        {
            label: `Ver ${CATEGORY.birth.subcategories.all.title}`,
            value: CATEGORY.birth.subcategories.all.searchCode
        },
        {
            label: CATEGORY.birth.subcategories.one_year.title,
            value: CATEGORY.birth.subcategories.one_year.searchCode
        },
        {
            label: CATEGORY.birth.subcategories.two_fourteen_years.title,
            value: CATEGORY.birth.subcategories.two_fourteen_years.searchCode
        },
        {
            label: CATEGORY.birth.subcategories.fifteen_years.title,
            value: CATEGORY.birth.subcategories.fifteen_years.searchCode
        },
        {
            label: CATEGORY.birth.subcategories.fifty_greater_years.title,
            value: CATEGORY.birth.subcategories.fifty_greater_years.searchCode
        },
        {
            label: CATEGORY.birth.subcategories.others.title,
            value: CATEGORY.birth.subcategories.others.searchCode
        }
    ]
}

export {
    BIRTH_SUBCATEGORIES_MODAL
}