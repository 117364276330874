import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams, Redirect } from 'react-router';
import { Container, Col, Modal, RadioGroup, TextInput, Collection, CollectionItem } from 'react-materialize';
import $ from 'jquery';
import styled from 'styled-components';
import Row from '../organism/row.jsx';
import Path from '../molecules/path.jsx';
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Share from "yet-another-react-lightbox/plugins/share";
import Captions from "yet-another-react-lightbox/plugins/captions";
import PhotoAlbum from "react-photo-album";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { getPicturesFromSearchCode } from '../../utils/managersearch.js';
import { Label } from '../atoms/label.jsx';
import { COLOR, FONT_FAMILY, SCREEN_MEDIA, LABEL, SIZE } from '../../utils/constants.js';
import {
    getSearchCodeFromSearchLabel,
    getCatalogSuggestionList,
    modifyPicturesToLowList
} from '../../utils/managersearch.js';
import Button from '../atoms/button.jsx';
import TextIcon from '../atoms/texticon.jsx';


import PathIcSarch from '../../icons/ic_search.svg'


const TextInputLayer = styled.div`
    height: 45px;
    width: 100%;
    border: 2px solid ${COLOR.ferra};
    border-radius: 4px;
    cursor: text;
`;

const SearchSuggestionBackground = styled.div`
    height: 100%; // It is not working, this is set dynamically
    width: 100%;
    position: absolute;
    background-color: ${COLOR.background};
    display: none;
    @media only screen and ${SCREEN_MEDIA.small} {
        top: 0px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        top: 0px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        top: 0px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        top: 0px;
    }
    z-index: 1;
`;

const SearcherSuggestionDiv = styled.div`
    height: 400px;
    background-color: ${COLOR.white};
    overflow: auto;
    position: absolute;
    @media only screen and ${SCREEN_MEDIA.small} {
        width:90%;
        top: 90px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        width:85%;
        top: 90px;
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        width:70%;
        top: 90px;
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        width:70%;
        top: 90px;
    }
`


const BodyPictures = () => {

    const { searchCode } = useParams();

    const searchResultPictureList = getPicturesFromSearchCode(searchCode)

    var searchResulPictureLowList = new Array()
    searchResultPictureList.forEach((item) => {
        searchResulPictureLowList.push(Object.assign({}, item))
    })
    modifyPicturesToLowList(searchResulPictureLowList)

    const wholeCatalogSuggestions = getCatalogSuggestionList('')

    const [index, setIndex] = useState(-1);

    const [descriptionMaxLines, setDescriptionMaxLines] = useState(3);
    const [descriptionTextAlign, setDescriptionTextAlign] = useState("end"); //"start" | "end" | "center"

    const [searchValue, setSearchValue] = useState('');
    const [searchSuggestionList, setSearchSuggestionList] = useState(wholeCatalogSuggestions)

    const navigate = useNavigate();
    
    const searchInputTextRef = useRef()

    useEffect(() => {
        window.scrollTo(0, 0);

        $(window).on('load', function() {
            $('#search_suggestion_background').css('height', $('#root').height());
        });
    }, [])

    const handleSearchOnkeyDown = (event) => {
        if (searchValue.length < 3) return

        if (event.key === 'Enter') {
            handleOnSearchDismissed()
            console.log(`searchValue: ${searchValue}`)
            const searchCode = getSearchCodeFromSearchLabel(searchValue)
            console.log(`searchCode: ${searchCode}`)

            if (searchCode === '') {
                alert(`No se encontraron resultados: ${searchCode}`)
            } else {
                navigate(`/pictures/${searchCode}`)
            }
        }
    }

    const handleOnSearchDismissed = () => {
        $(searchInputTextRef.current).blur()
        $('#search_suggestion_background').css('display', 'none')
    }
    
    const onSearchInputLayerClicked = () => {
        $('#search_suggestion_background').css('display', 'block')
        $(searchInputTextRef.current).focus()
    }

    const onSearchInputTextChanges = (event) => {
        const searchText = event.target.value
        const searchTrim = searchText.trim()
        setSearchValue(searchTrim)
        const searchSuggestionList = getCatalogSuggestionList(searchTrim)
        setSearchSuggestionList(searchSuggestionList)
    }


    return(
        <div>
            <Container>
                <Row className='valign-wrapper' margin='40px 0'>
                    <TextInputLayer
                        onClick={ () => { onSearchInputLayerClicked() } }>
                        <Label
                        text_color={COLOR.gray}
                        text_size='20px'
                        font_family={FONT_FAMILY.avenirBook}
                        margin='7px 14px'>
                        Buscar temática
                        </Label>
                    </TextInputLayer>
                </Row>

                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={`${LABEL.thematics}/${LABEL.pictures}`} />
                </Row>

                <Row margin='20px 0'>
                <PhotoAlbum
                    layout="rows"
                    photos={searchResulPictureLowList}
                    targetRowHeight={300}
                    onClick={({ index: current }) => setIndex(current)}
                />

                <Lightbox
                    index={index}
                    plugins={ [Counter, Share, Captions] }
                    captions={ { descriptionTextAlign, descriptionMaxLines } }
                    counter={ { container: { style: { top: "unset", bottom: 0 } } } }
                    slides={searchResultPictureList}
                    open={index >= 0}
                    close={() => setIndex(-1)}
                />
                </Row>
            </Container>

            <SearchSuggestionBackground
                id='search_suggestion_background'>
                <Container>
                <Row margin='40px 0'>
                    <Col s={8} m={8} l={10} xl={11}>
                        <TextInput
                        ref={searchInputTextRef}
                        placeholder='Buscar temática'
                        onChange={ (event) => { onSearchInputTextChanges(event) } }
                        onKeyDown={ (event) => { handleSearchOnkeyDown(event) } }/>
                    </Col>
                    <Col s={4} m={4} l={2} xl={1}>
                    <Button
                        onClick={ () => { handleOnSearchDismissed() } }
                        height='40px'>
                        <Label
                        font_family={FONT_FAMILY.avenirLight}
                        text_color={COLOR.ferra}>
                            { LABEL.cancel }
                        </Label>
                    </Button>
                    
                    </Col>
                </Row>
                <Row>
                    <SearcherSuggestionDiv>
                    <Collection>
                    {
                        searchSuggestionList.map((searchSuggestion, index) =>
                            <CollectionItem
                                key={index}
                                href={`/pictures/${searchSuggestion.searchCode}`}>
                                <TextIcon
                                    ic_path={PathIcSarch}
                                    ic_size='18px'
                                    ic_color={COLOR.ferra}>
                                </TextIcon>
                                <Label
                                    text_size={SIZE.subtitle}
                                    text_color={COLOR.ferra}
                                    font_family={FONT_FAMILY.avenirMedium}> 
                                    {searchSuggestion.title}
                                </Label>
                                
                            </CollectionItem>
                        )
                    }
                    </Collection>
                    </SearcherSuggestionDiv>
                </Row>
                </Container>
            </SearchSuggestionBackground>
        </div>
    )
}

export default BodyPictures;