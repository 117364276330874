import { CATEGORY } from './catalog_categories.js';
import { THEMATIC } from './catalog_thematics.js';
import { PICTURES_BIRTH } from './pictures_birth.js';
import { PICTURES_BABYSHOWER } from './pictures_babyshower.js'
import { PICTURES_CELEBRATIONS } from './pictures_celebrations.js'
import { PICTURES_ROMANTIC } from './pictures_romantic.js'
import { PICTURES_BAPTISM } from './pictures_baptism.js'
import { PICTURES_WEDDING } from './pictures_wedding.js'




const getSearchCodeFromSearchLabel = (searchLabel) => {
    const wholeCatalogSuggestions = getCatalogSuggestionList('')
    var searchCode = '';
    for (let i = 0; i < wholeCatalogSuggestions.length; i++) {
        const item = wholeCatalogSuggestions[i]
        if (item.searchLabel.includes(searchLabel.toLowerCase())) {
            searchCode = item.searchCode
            continue; 
        }
    }
    return searchCode;
}



const getCatalogSuggestionList = (searchLabel) => {
    var arrayFiltered = new Array()

    for (let key in CATEGORY) {
        if (CATEGORY[key].searchLabel.includes(searchLabel.toLowerCase())) {
            arrayFiltered.push(CATEGORY[key])
        }
    }
    for (let key in CATEGORY.birth.subcategories) {
        if (CATEGORY.birth.subcategories[key].searchLabel.includes(searchLabel.toLowerCase())) {
            arrayFiltered.push(CATEGORY.birth.subcategories[key])
        }
    }
    for (let key in THEMATIC) {
        if (THEMATIC[key].searchLabel.includes(searchLabel.toLowerCase())) {
            arrayFiltered.push(THEMATIC[key])
        }
    }
    return arrayFiltered
}



const getPicturesFromSearchCode = (searchCode) => {
    const searchResultFromCategoryList = getSearchMatchesFromCategories(searchCode)
    if (searchResultFromCategoryList.length > 0) {
        return searchResultFromCategoryList
    }

    const searchResultFromBirthSubcategoryList = getSearchMatchesFromBirthSubcategories(searchCode)
    if (searchResultFromBirthSubcategoryList.length > 0) {
        return searchResultFromBirthSubcategoryList
    }

    //TODO: getSearchMatchesFromCelebrationsSubcategories. 

    return getSearchMatchesFromThematics(searchCode)
}

const getSearchMatchesFromCategories = (searchCode) => {
    if (CATEGORY.birth.searchCode.includes(searchCode)) {
        return PICTURES_BIRTH
    }
    else if (CATEGORY.babyshower.searchCode.includes(searchCode)) {
        return PICTURES_BABYSHOWER
    }
    else if (CATEGORY.celebrations.searchCode.includes(searchCode)) {
        return PICTURES_CELEBRATIONS
    }
    else if (CATEGORY.romantic.searchCode.includes(searchCode)) {
        return PICTURES_ROMANTIC
    }
    else if (CATEGORY.baptism.searchCode.includes(searchCode)) {
        return PICTURES_BAPTISM
    }
    else if (CATEGORY.wedding.searchCode.includes(searchCode)) {
        return PICTURES_WEDDING
    }
    return new Array()
}

const getSearchMatchesFromBirthSubcategories = (searchCode) => {
    var arrayFiltered = new Array()
    if (CATEGORY.birth.subcategories.all.searchCode === searchCode) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH)
    }
    if (CATEGORY.birth.subcategories.one_year.searchCode.includes(searchCode)) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByOneYear))
    }
    if (CATEGORY.birth.subcategories.two_fourteen_years.searchCode.includes(searchCode)) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByTwoForteenYears))
    }
    if (CATEGORY.birth.subcategories.fifteen_years.searchCode.includes(searchCode)) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByFifeteen))
    }
    if (CATEGORY.birth.subcategories.fifty_greater_years.searchCode.includes(searchCode)) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByFiftyGreater))
    }
    if (CATEGORY.birth.subcategories.others.searchCode.includes(searchCode)) {
        arrayFiltered = arrayFiltered.concat(PICTURES_BIRTH.filter(filterBirthByOthers))
    }
    return arrayFiltered;
}

function filterBirthByOneYear(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.one_year) {
      return true;
    }
    return false;
}

function filterBirthByTwoForteenYears(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.two_fourteen_years) {
      return true;
    }
    return false;
}

function filterBirthByFifeteen(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.fifteen_years) {
      return true;
    }
    return false;
}

function filterBirthByFiftyGreater(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.fifty_greater_years) {
      return true;
    }
    return false;
}

function filterBirthByOthers(item) {
    if (item.subcategory == CATEGORY.birth.subcategories.others) {
      return true;
    }
    return false;
}

const getSearchMatchesFromThematics = (searchCode) => {
    var arrayFiltered = new Array()
    PICTURES_BIRTH.forEach((item) => {
        if (item.thematic.searchCode.includes(searchCode)) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_BABYSHOWER.forEach((item) => {
        if (item.thematic.searchCode.includes(searchCode)) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_CELEBRATIONS.forEach((item) => {
        if (item.thematic.searchCode.includes(searchCode)) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_ROMANTIC.forEach((item) => {
        if (item.thematic.searchCode.includes(searchCode)) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_BAPTISM.forEach((item) => {
        if (item.thematic.searchCode.includes(searchCode)) {
            arrayFiltered.push(item)
        }
    })
    PICTURES_WEDDING.forEach((item) => {
        if (item.thematic.searchCode.includes(searchCode)) {
            arrayFiltered.push(item)
        }
    })
    return arrayFiltered;
}


const modifyPicturesToLowList = (pictureList) => {
    pictureList.forEach((item) => {
        if (item.width === 1200) {
            item.width = 300
        }
        if (item.width === 720) {
            item.width = 180
        }
        if (item.width === 900) {
            item.width = 225
        }
        if (item.height === 1000) {
            item.height = 250
        }
    })
}


export {
    getPicturesFromSearchCode,
    getSearchCodeFromSearchLabel,
    modifyPicturesToLowList,
    getCatalogSuggestionList
}