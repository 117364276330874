
const CATEGORY = {
    birth: {
        title: "Cumpleaños",
        searchLabel: "cumpleaños",
        searchCode: "birth",
        subcategories: {
            one_year: {
                title: "1 año",
                searchLabel: "1 año, un año, primer año, fiesta infantil, fiestas infantiles",
                searchCode: "birth_one_year"
            },
            two_fourteen_years: {
                title: "2 a 14 años",
                searchLabel: "2 a 14 años, dos a catorce años, fiesta infantil, fiestas infantiles",
                searchCode: "birth_two_fourteen_years"
            },
            fifteen_years: {
                title: "15 años",
                searchLabel: "mis 15 años, mis quince años, mi quinceañera, mi quinceañero",
                searchCode: "birth_fifteen_years"
            },
            fifty_greater_years: {
                title: "50 años y mayores",
                searchLabel: "50 años y mayores, cincuenta años y mayores",
                searchCode: "birth_fifty_greater_years"
            },
            others: {
                title: "Otros cumpleaños",
                searchLabel: "otros cumpleaños",
                searchCode: "birth_others"
            },
            all: {
                title: "Todos los cumpleaños",
                searchLabel: "----",
                searchCode: "birth_all"
            }
        }
    },
    babyshower: {
        title: "Baby Shower",
        searchLabel: "babyshower, baby shower",
        searchCode: "babyshower"
    },
    celebrations: {
        title: "Celebraciones",
        searchLabel: "celebraciones",
        searchCode: "celebrations"
    },
    romantic: {
        title: "Ambiente Romántico",
        searchLabel: "ambiente romántico, ambiente romantico, aniversarios, pedida de mano, cena romántica, cena romantica",
        searchCode: "romantic"
    },
    baptism: {
        title: "Bautizo",
        searchLabel: "bautizo",
        searchCode: "baptism"
    },
    wedding: {
        title: "Matrimonio",
        searchLabel: "matrimonios, bodas",
        searchCode: "wedding"
    }
}

export { CATEGORY }