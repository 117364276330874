const XMAS_WINNERS_MODAL = {
    id: 'modalXmasWinners',
    title: 'Muchas felicidades a los ganadores del sorteo!',
    winners: [
        {
            name: 'Kelly Alvarez'
        },
        {
            name: 'Meli Cabrera'
        },
        {
            name: 'Haydee Escobal Ascate'
        }
    ]
}

export {
    XMAS_WINNERS_MODAL
}