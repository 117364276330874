import { CATEGORY } from './catalog_categories.js';
import { THEMATIC } from './catalog_thematics.js';

import PathImgCelebration_001 from '../images_categories/celebrations/img_001.jpg';
import PathImgCelebration_002 from '../images_categories/celebrations/img_002.jpg';
import PathImgCelebration_003 from '../images_categories/celebrations/img_003.jpg';
import PathImgCelebration_004 from '../images_categories/celebrations/img_004.jpg';
import PathImgCelebration_005_000 from '../images_categories/celebrations/img_005_00.jpg';
import PathImgCelebration_005_001 from '../images_categories/celebrations/img_005_001.jpg';
import PathImgCelebration_005_002 from '../images_categories/celebrations/img_005_02.jpg';
import PathImgCelebration_005_004 from '../images_categories/celebrations/img_005_04.jpg';
import PathImgCelebration_006 from '../images_categories/celebrations/img_006.jpg';
import PathImgCelebration_008_001 from '../images_categories/celebrations/img_008_001.jpg';
import PathImgCelebration_008_002 from '../images_categories/celebrations/img_008_002.jpg';
import PathImgCelebration_008_004 from '../images_categories/celebrations/img_008_004.jpg';
import PathImgCelebration_009_001 from '../images_categories/celebrations/img_009_001.jpg';
import PathImgCelebration_009_003 from '../images_categories/celebrations/img_009_003.jpg';
import PathImgCelebration_010_001 from '../images_categories/celebrations/img_010_001.jpg';
import PathImgCelebration_010_002 from '../images_categories/celebrations/img_010_002.jpg';
import PathImgCelebration_011_001 from '../images_categories/celebrations/img_011_001.jpg';
import PathImgCelebration_011_002 from '../images_categories/celebrations/img_011_002.jpg';
import PathImgCelebration_011_003 from '../images_categories/celebrations/img_011_003.jpg';
import PathImgCelebration_011_007 from '../images_categories/celebrations/img_011_007.jpg';
import PathImgCelebration_012_001 from '../images_categories/celebrations/img_012_001.jpg';
import PathImgCelebration_012_002 from '../images_categories/celebrations/img_012_002.jpg';
import PathImgCelebration_013_001 from '../images_categories/celebrations/img_013_001.jpg';
import PathImgCelebration_013_002 from '../images_categories/celebrations/img_013_002.jpg';
import PathImgCelebration_014_001 from '../images_categories/celebrations/img_014_001.jpg';
import PathImgCelebration_014_004 from '../images_categories/celebrations/img_014_004.jpg';
import PathImgCelebration_014_005 from '../images_categories/celebrations/img_014_005.jpg';
import PathImgCelebration_017_001 from '../images_categories/celebrations/img_017_001.jpg';
import PathImgCelebration_017_003 from '../images_categories/celebrations/img_017_003.jpg';
import PathImgCelebration_017_006 from '../images_categories/celebrations/img_017_006.jpg';
import PathImgCelebration_017_007 from '../images_categories/celebrations/img_017_007.jpg';
import PathImgCelebration_018_002 from '../images_categories/celebrations/img_018_002.jpg';
import PathImgCelebration_018_003 from '../images_categories/celebrations/img_018_003.jpg';
import PathImgCelebration_018_004 from '../images_categories/celebrations/img_018_004.jpg';
import PathImgCelebration_019_001 from '../images_categories/celebrations/img_019_001.jpg';
import PathImgCelebration_020_001 from '../images_categories/celebrations/img_020_001.jpg';
import PathImgCelebration_020_003 from '../images_categories/celebrations/img_020_003.jpg';
import PathImgCelebration_021_001 from '../images_categories/celebrations/img_021_001.jpg';
import PathImgCelebration_021_003 from '../images_categories/celebrations/img_021_003.jpg';
import PathImgCelebration_021_004 from '../images_categories/celebrations/img_021_004.jpg';
import PathImgCelebration_021_005 from '../images_categories/celebrations/img_021_005.jpg';
import PathImgCelebration_022_001 from '../images_categories/celebrations/img_022_001.jpg';
import PathImgCelebration_022_002 from '../images_categories/celebrations/img_022_002.jpg';
import PathImgCelebration_022_003 from '../images_categories/celebrations/img_022_003.jpg';


const PICTURES_CELEBRATIONS = [
    {
        src: PathImgCelebration_011_007,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_011_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_011_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_011_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolAnniversary,
        title: THEMATIC.schoolAnniversary.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_017_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolPromo,
        title: THEMATIC.schoolPromo.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_017_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolPromo,
        title: THEMATIC.schoolPromo.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_017_006,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolPromo,
        title: THEMATIC.schoolPromo.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_017_007,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolPromo,
        title: THEMATIC.schoolPromo.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_006,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_018_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_018_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_018_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_013_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_013_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_019_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_020_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_020_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_022_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolPromo,
        title: THEMATIC.schoolPromo.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_022_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolPromo,
        title: THEMATIC.schoolPromo.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_022_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.schoolPromo,
        title: THEMATIC.schoolPromo.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_008_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_008_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_008_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_005_000,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_005_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_005_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_005_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_021_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_021_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_021_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_021_005,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.christmas,
        title: THEMATIC.christmas.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_009_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_009_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.motherDay,
        title: THEMATIC.motherDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_010_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_010_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_014_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_014_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_014_005,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_004,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.childDay,
        title: THEMATIC.childDay.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_012_001,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_012_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title,
        width: 720,
        height: 1000
    },
    {
        src: PathImgCelebration_002,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title,
        width: 1200,
        height: 1000
    },
    {
        src: PathImgCelebration_003,
        category: CATEGORY.celebrations,
        thematic: THEMATIC.graduation,
        title: THEMATIC.graduation.title,
        width: 1200,
        height: 1000
    },
]

export { PICTURES_CELEBRATIONS }